import bus from "vue3-eventbus";
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFirestore } from "firebase/firestore";
import { getDatabase, ref, onValue, set } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  updatePassword,
} from "firebase/auth";
import { doc, getDocs, getDoc, updateDoc } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

let firebaseConfig;

if (process.env.NODE_ENV === "development" || ["beta.workflow.printcarepremedia.com", "ppm-workflow-dev.web.app", "ppm-workflow-dev.firebaseapp.com"].includes(window.location.hostname)) {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = "eda85057-161c-4dee-83ea-fa0258ec1021";
  firebaseConfig = {
    apiKey: "AIzaSyC5ZyrgElGvb-HVe9qTl1V3sKhgtpivhZE",
    authDomain: "ppm-workflow-dev.firebaseapp.com",
    projectId: "ppm-workflow-dev",
    storageBucket: "ppm-workflow-dev.appspot.com",
    messagingSenderId: "26440542830",
    appId: "1:26440542830:web:8b26aad57018e52a49147f",
    measurementId: "G-17YWJSVDCY",
    databaseURL:
      "https://ppm-workflow-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
  };
} else if (process.env.NODE_ENV == "production") {
  firebaseConfig = {
    apiKey: "AIzaSyCxuRKYu_DUE0xocsoCpBFlayfCgOJlsRk",
    authDomain: "ppm-workflow-management.firebaseapp.com",
    projectId: "ppm-workflow-management",
    storageBucket: "ppm-workflow-management.appspot.com",
    messagingSenderId: "1079972950070",
    appId: "1:1079972950070:web:d6779d537ca07393276897",
    measurementId: "G-TS2C8NPF0Q",
    databaseURL:
      "https://ppm-workflow-management-default-rtdb.asia-southeast1.firebasedatabase.app",
  };
}

console.log(process.env.NODE_ENV + " - " +window.location.hostname); // Outputs: 'development', 'production', or 'test'

// Initialize Firebase and main exports--------------------------------------------------------------------
const app = initializeApp(firebaseConfig);
const region = "asia-south1";
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const database = getDatabase(app);
export const functions = getFunctions(app, region);
export const storage = getStorage(app);
export const messaging = getMessaging(app);
export const analytics = getAnalytics(app);

//App Ckeck------------------------------------------------------------------------------------------------

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LdJGBknAAAAAG7NLBa_D5paUsSFj8Vm9-NbyDwV"),
  isTokenAutoRefreshEnabled: true,
});

//firebase functions---------------------------------------------------------------------------------------

const addArtwork = httpsCallable(functions, "addArtwork");
const addCustomers = httpsCallable(functions, "addCustomers");
const addCTP = httpsCallable(functions, "addCTP");
const addDispatch = httpsCallable(functions, "addDispatch");
const addItem = httpsCallable(functions, "addItem");
const addVersion = httpsCallable(functions, "addVersion");
const updateItem = httpsCallable(functions, "updateItem");
const updateVersion = httpsCallable(functions, "updateVersion");
const uploadRevision = httpsCallable(functions, "uploadRevision");
const updateRevision = httpsCallable(functions, "updateRevision");
const assignRevision = httpsCallable(functions, "assignRevision");
const deleteRevision = httpsCallable(functions, "deleteRevision");
const approveRevision = httpsCallable(functions, "approveRevision");
const unApproveRevision = httpsCallable(functions, "unApproveRevision");
const addChat = httpsCallable(functions, "addChat");
const addOSL = httpsCallable(functions, "addOSL");
const addPlateout = httpsCallable(functions, "addPlateout");
const addRepro = httpsCallable(functions, "addRepro");
const addRemoveStock = httpsCallable(functions, "addRemoveStock");
const addVPS = httpsCallable(functions, "addVPS");
const addvariable = httpsCallable(functions, "addvariable");
const assignJob = httpsCallable(functions, "assignJob");
const charges = httpsCallable(functions, "charges");
const discount = httpsCallable(functions, "discount");
const convertArtwork = httpsCallable(functions, "convertArtwork");
const createNewJobID = httpsCallable(functions, "createNewJobID");
const createOldJobID = httpsCallable(functions, "createOldJobID");
const createSAPSO = httpsCallable(functions, "createSAPSO");
const createPPMSO = httpsCallable(functions, "createPPMSO");
const updateJob = httpsCallable(functions, "updateJob");
const updateSO = httpsCallable(functions, "updateSO");
const createUser = httpsCallable(functions, "createUser");
const deleteOffSet = httpsCallable(functions, "deleteOffSet");
const deleteArtwork = httpsCallable(functions, "deleteArtwork");
const holdJob = httpsCallable(functions, "holdJob");
const markComplete = httpsCallable(functions, "markComplete");
const addPlateSlip = httpsCallable(functions, "addPlateSlip");
const mergeArtwork = httpsCallable(functions, "mergeArtwork");
const unmergeArtwork = httpsCallable(functions, "unmergeArtwork");
const passwordReset = httpsCallable(functions, "passwordReset");
const redoJob = httpsCallable(functions, "redoJob");
const sendBack = httpsCallable(functions, "sendBack");
const setUpdate = httpsCallable(functions, "setUpdate");
const unassignJob = httpsCallable(functions, "unassignJob");
const updateOffSet = httpsCallable(functions, "updateOffSet");
const updateUser = httpsCallable(functions, "updateUser");
const createInvoice = httpsCallable(functions, "createInvoice");
const approvedInvoice = httpsCallable(functions, "approvedInvoice");
const deleteInvoice = httpsCallable(functions, "deleteInvoice");
const statesReport = httpsCallable(functions, "statesReport");
const uploadPurchaseInvoice = httpsCallable(functions, "uploadPurchaseInvoice");
const verifyPurchaseInvoice = httpsCallable(functions, "verifyPurchaseInvoice");
const approvePurchaseInvoice = httpsCallable(functions, "approvePurchaseInvoice");
const createGatePass = httpsCallable(functions, "createGatePass");
const approveGatePass = httpsCallable(functions, "approveGatePass");
const rejectGatePass = httpsCallable(functions, "rejectGatePass");
const updateGatePass = httpsCallable(functions, "updateGatePass");
const deleteGatePass = httpsCallable(functions, "deleteGatePass");
const verifyGatePass = httpsCallable(functions, "verifyGatePass");
const printGatePass = httpsCallable(functions, "printGatePass");

export {
  addArtwork,
  addCTP,
  addCustomers,
  addDispatch,
  addItem,
  addVersion,
  updateItem,
  updateVersion,
  uploadRevision,
  updateRevision,
  assignRevision,
  deleteRevision,
  approveRevision,
  unApproveRevision,
  addChat,
  addOSL,
  addPlateout,
  addRepro,
  addRemoveStock,
  addVPS,
  addvariable,
  assignJob,
  charges,
  discount,
  convertArtwork,
  createNewJobID,
  createOldJobID,
  createSAPSO,
  createPPMSO,
  updateJob,
  updateSO,
  createUser,
  deleteOffSet,
  deleteArtwork,
  holdJob,
  markComplete,
  addPlateSlip,
  mergeArtwork,
  unmergeArtwork,
  passwordReset,
  redoJob,
  sendBack,
  setUpdate,
  unassignJob,
  updateOffSet,
  updateUser,
  createInvoice,
  approvedInvoice,
  deleteInvoice,
  statesReport,
  uploadPurchaseInvoice,
  verifyPurchaseInvoice,
  approvePurchaseInvoice,
  createGatePass,
  approveGatePass,
  rejectGatePass,
  updateGatePass,
  deleteGatePass,
  verifyGatePass,
  printGatePass,
};

//Firebase Auth related functions--------------------------------------------------------------------------

export function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      async (user) => {
        if (user != null || user != undefined) {
          // User Role
          const docRef = doc(firestore, "users", user.uid);
          const docSnap = await getDoc(docRef);
          const docData = docSnap.data();

          // Store data in localStorage
          localStorage.email = user.email;
          localStorage.uid = user.uid;
          localStorage.userRole = docData.role;
          localStorage.userName = docData.name;

          // Resolve with user object and additional data
          resolve({
            user,
            role: docData.role,
            name: docData.name,
          });
        } else {
          // Resolve with null if no user is logged in
          resolve(null);
        }

        unsubscribe();
      },
      reject
    );
  });
}

export async function login(email, password) {
  return await signInWithEmailAndPassword(auth, email, password);
}

export async function changePassword(newPassword) {
  return await updatePassword(auth.currentUser, newPassword);
}

export async function logout() {
  await signOut(auth);
  bus.off("loading");
  localStorage.clear();
}
