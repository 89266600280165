<template>
    <v-list-item v-if="!this.$vuetify.display.mobile" lines="two" :title="userEmail">
        <div class="text-end text-subtitle-2">Logged in as {{ userRole }} </div>
    </v-list-item>

    <!-- Notifications Menu -->
    <v-menu v-model:opened="notificationPanel" offset-y :close-on-content-click="false" max-height="464"
        transition="slide-y-transition">
        <template #activator="{ props }">
            <v-btn variant="text" density="compact" v-bind="props" :disabled="notificationsCount == 0" stacked>
                <v-badge v-if="notificationsCount > 0" :content="notificationsCount" color="primary">
                    <v-icon>mdi-bell</v-icon>
                </v-badge>
                <v-icon v-else>mdi-bell</v-icon>
            </v-btn>
        </template>

        <v-card min-width="450" class="mt-1" color="navBar" rounded="lg">
            <v-list lines="two" bg-color="cards" @scroll.passive="handleScroll"
                style="overflow-y: auto; max-height: 400px;">
                <div v-for="(notification, index) in notifications" :key="index">
                    <!-- If notification is being deleted, show skeleton loader -->
                    <v-skeleton-loader v-if="isDeleting[notification.id]" type="list-item-avatar-two-line"
                        color="cards"></v-skeleton-loader>

                    <!-- If notification has a link and is not being deleted -->
                    <v-list-item v-else-if="notification.link" @click="goToLink(notification.link)" density="compact">
                        <template #prepend>
                            <v-avatar :color="this.getColor(notification.icon)">
                                <v-icon>{{ notification.icon }}</v-icon>
                            </v-avatar>
                        </template>

                        <v-list-item-title>{{ notification.title }}</v-list-item-title>
                        <v-list-item-subtitle>{{ notification.body }}</v-list-item-subtitle>

                        <template #append>
                            <v-btn icon variant="text" @click.stop="clearNotification(notification.id)">
                                <v-icon color="secondary" size="small">mdi-close</v-icon>
                            </v-btn>
                        </template>
                    </v-list-item>

                    <!-- If notification has no link and is not being deleted -->
                    <v-list-item v-else density="compact">
                        <template #prepend>
                            <v-avatar :color="this.getColor(notification.icon)">
                                <v-icon>{{ notification.icon }}</v-icon>
                            </v-avatar>
                        </template>

                        <v-list-item-title>{{ notification.title }}</v-list-item-title>
                        <v-list-item-subtitle>{{ notification.text }}</v-list-item-subtitle>

                        <template #append>
                            <v-btn icon variant="text" @click.stop="clearNotification(notification.id)">
                                <v-icon color="secondary" size="small">mdi-close</v-icon>
                            </v-btn>
                        </template>
                    </v-list-item>
                </div>
                <!-- Loading more indicator -->
                <div v-if="loadingMore" class="text-center my-2">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
            </v-list>
            <v-progress-linear :indeterminate="loadingMore"></v-progress-linear>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn size="small" :loading="isLoading" @click="clearAllNotifications">Clear Loaded</v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>

    <!-- User Menu -->
    <div class="mr-2">
        <v-menu v-model="menu" :close-on-content-click="false" location="bottom">
            <template #activator="{ props }">
                <v-btn icon v-bind="props">
                    <v-avatar :color="getColorByInitial(userName)" size="large">
                        <span class="text-h5">{{ getInitials(userName) }}</span>
                    </v-avatar>
                </v-btn>
            </template>

            <v-card min-width="300" color="cards">
                <div class="my-6 mx-auto text-center">
                    <v-avatar :color="getColorByInitial(userName)" size="large">
                        <span class="text-h5">{{ getInitials(userName) }}</span>
                    </v-avatar>
                    <h3>{{ userName }}</h3>
                </div>

                <v-divider></v-divider>

                <v-list bg-color="navBar">
                    <v-list-item>
                        <v-btn block color="primary" prepend-icon="mdi-account-box-outline"
                            @click="gotoPage('/myaccount')" variant="tonal">
                            My Account
                        </v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn block color="primary" prepend-icon="mdi-form-textbox-password"
                            @click="gotoPage('/changepassword')" variant="tonal">
                            Change Password
                        </v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn block color="primary" prepend-icon="mdi-theme-light-dark" @click="changeTheme"
                            variant="tonal">
                            Change Theme
                        </v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn block color="primary" @click="logout"> Logout </v-btn>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import router from "@/router";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useTheme } from "vuetify";
import { onMessage } from "firebase/messaging";
import { onDisconnect, set, ref, getDatabase } from "firebase/database";
import { getCurrentUser, logout, firestore, messaging } from "@/firebase";
import { doc, getDocs, collection, deleteDoc, query, orderBy, startAfter, limit, getCountFromServer, onSnapshot } from "firebase/firestore";

export default {
    data() {
        return {
            userEmail: localStorage.email,
            userRole: localStorage.userRole,
            userName: localStorage.userName,
            menu: false,
            notificationPanel: false,
            notifications: [],
            notificationsCount: 0,
            isLoading: false,
            isDeleting: {},
            lastVisibleNotification: null,
            loadingMore: false,
            allNotificationsLoaded: false,
            intervalId: null,
            colorMap: {
                A: "red",
                B: "pink",
                C: "purple",
                D: "deep-purple",
                E: "indigo",
                F: "blue",
                G: "light-blue",
                H: "cyan",
                I: "teal",
                J: "green",
                K: "light-green",
                L: "lime",
                M: "yellow",
                N: "amber",
                O: "orange",
                P: "deep-orange",
                Q: "brown",
                R: "blue-grey",
                S: "grey",
                T: "red lighten-2",
                U: "pink lighten-2",
                V: "purple lighten-2",
                W: "deep-purple lighten-2",
                X: "indigo lighten-2",
                Y: "blue lighten-2",
                Z: "cyan lighten-2"
            },
        };
    },
    async created() {
        await getCurrentUser();
        this.userEmail = localStorage.email;
        this.userRole = localStorage.userRole;
        this.userName = localStorage.userName;

        onMessage(messaging, (payload) => {
            toast.info(payload.notification.title, {
                "theme": this.$vuetify.theme.name == 'lightTheme' ? 'light' : 'dark',
                "autoClose": 10000,
            });
            this.checkForNewNotifications();
        });

        const userRef = doc(firestore, "users", localStorage.uid);
        const notificationsRef = collection(userRef, "notifications");

        let initialLoad = true; // Flag to track initial load

        // Listen for real-time updates on notifications
        this.unsubscribe = onSnapshot(
            query(notificationsRef, orderBy("timestamp", "desc")),
            (snapshot) => {
                if (initialLoad) {
                    // Initial load: populate notifications but don't show toast
                    this.notifications = snapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    this.notificationsCount = snapshot.size;
                    initialLoad = false; // Set flag to false after the first load
                } else {
                    // Handle real-time changes after initial load
                    snapshot.docChanges().forEach((change) => {
                        if (change.type === "added") {
                            const notification = { id: change.doc.id, ...change.doc.data() };
                            this.notifications.unshift(notification); // Add to the top of the list

                            // Show toast for new notification
                            toast.info(notification.title, {
                                theme: this.$vuetify.theme.name === "lightTheme" ? "light" : "dark",
                                autoClose: 6000,
                            });
                        }
                    });

                    // Update notifications count
                    this.notificationsCount = snapshot.size;
                }
            }
        );

        // Set online status when connected
        const realtimeDB = getDatabase();
        const userRefRealtimeDB = ref(realtimeDB, `onlineUsers/${localStorage.uid}`);
        set(userRefRealtimeDB, { online: true });
        // Handle disconnect
        onDisconnect(userRefRealtimeDB).set({ online: false });
    },
    beforeUnmount() {
        // Clear the Firestore listener
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    },
    methods: {
        gotoPage(path) {
            this.menu = false;
            router.push(path);
        },
        async handleScroll(event) {
            const list = event.target;
            // Check if scrolled to bottom
            if (list.scrollHeight - list.scrollTop === list.clientHeight) {
                if (!this.loadingMore) {
                    await this.fetchNotifications(true); // Load more notifications
                }
            }
        },
        async fetchNotifications(loadMore = false) {
            // Check if all notifications are already loaded
            if (this.allNotificationsLoaded) return;
            this.loadingMore = loadMore;

            const userRef = doc(firestore, "users", localStorage.uid);
            let notificationsQuery = query(
                collection(userRef, "notifications"),
                orderBy("timestamp", "desc"),
                limit(7) // Fetch a batch of 7 notifications
            );

            // If loading more, use startAfter to paginate
            if (loadMore && this.lastVisibleNotification) {
                notificationsQuery = query(
                    collection(userRef, "notifications"),
                    orderBy("timestamp", "desc"),
                    startAfter(this.lastVisibleNotification),
                    limit(7)
                );
            }

            const notificationSnapshot = await getDocs(notificationsQuery);

            // If there are no more notifications
            if (notificationSnapshot.docs.length === 0) {
                this.allNotificationsLoaded = true; // Set flag if all notifications are loaded
            } else {
                // Update last visible notification for pagination
                this.lastVisibleNotification = notificationSnapshot.docs[notificationSnapshot.docs.length - 1];

                // If loading more, append new notifications, else set notifications
                if (loadMore) {
                    this.notifications.push(...notificationSnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    })));
                } else {
                    this.notifications = notificationSnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                }
            }
            this.loadingMore = false;
            this.isLoading = false;

            // Fetch total count of notifications (if needed)
            const notificationCountQuery = query(collection(userRef, "notifications"));
            const snapshot = await getCountFromServer(notificationCountQuery);
            this.notificationsCount = snapshot.data().count;
        },
        async checkForNewNotifications() {
            // Fetch new notifications since the last visible one
            const userRef = doc(firestore, "users", localStorage.uid);
            let notificationsQuery = query(
                collection(userRef, "notifications"),
                orderBy("timestamp", "desc"),
                limit(7) // Fetch the latest batch of 7 notifications
            );

            const notificationSnapshot = await getDocs(notificationsQuery);

            // If there are new notifications
            if (notificationSnapshot.docs.length > 0) {
                const newNotifications = notificationSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // Update the last visible notification for future pagination
                this.lastVisibleNotification = notificationSnapshot.docs[notificationSnapshot.docs.length - 1];

                // Prepend the new notifications to the existing list
                this.notifications.unshift(...newNotifications);

                // Update notifications count
                const notificationCountQuery = query(collection(userRef, "notifications"));
                const snapshot = await getCountFromServer(notificationCountQuery);
                this.notificationsCount = snapshot.data().count;
            }
        },
        async clearNotification(notificationId) {
            this.isDeleting[notificationId] = true; // Directly set the loading state

            const userRef = doc(firestore, "users", localStorage.uid);
            await deleteDoc(doc(userRef, "notifications", notificationId));

            // Filter out the deleted notification
            this.notifications = this.notifications.filter((n) => n.id !== notificationId);

            // Remove the loading state after the deletion is complete
            delete this.isDeleting[notificationId];
        },
        async clearAllNotifications() {
            // Set the button to loading state
            this.isLoading = true;

            try {
                // Clear only the fetched notifications
                const userRef = doc(firestore, "users", localStorage.uid);
                const notificationRefs = this.notifications.map((notification) =>
                    doc(userRef, "notifications", notification.id)
                );
                for (const ref of notificationRefs) {
                    await deleteDoc(ref);
                }
                this.notifications = [];

                // After clearing, fetch updated notifications
                await this.fetchNotifications();
            } catch (error) {
                console.error("Error clearing notifications: ", error);
            } finally {
                // Set loading state back to false once done
                this.isLoading = false;
            }
        },
        goToLink(link) {
            if (link) {
                window.open(link, "_blank"); // Open link in a new tab
            }
        },
        getColor(icon) {
            if (
                icon == "mdi-fast-forward" ||
                icon == "mdi-plus-circle" ||
                icon == "mdi-plus-circle-multiple"
            ) {
                return "green";
            } else if (
                icon == "mdi-rewind" ||
                icon == "mdi-close-circle-outline" ||
                icon == "mdi-database-alert"
            ) {
                return "red";
            } else if (icon == "mdi-update") {
                return "orange";
            } else if (icon == "mdi-palette") {
                return "purple";
            } else if (icon == "mdi-database-marker") {
                return "blue";
            } else if (icon == "mdi-note-plus") {
                return "#556B2F";
            } else if (icon == "mdi-merge") {
                return "indigo";
            } else if (icon == "mdi-check-all") {
                return "primary";
            } else if (icon == "mdi-wallet") {
                return "teal";
            } else if (icon == "mdi-spellcheck") {
                return "success";
            } else if (icon == "mdi-delete") {
                return "error";
            } else {
                return;
            }
        },
        changeTheme() {
            if (localStorage.darkmode == "true") {
                localStorage.darkmode = "false";
                this.setTheme(false);
            } else {
                localStorage.darkmode = "true";
                this.setTheme(true);
            }
        },
        logout: async function () {
            this.menu = false;
            await logout();
            this.setTheme(false);
            router.push("/login");
        },
        getInitials(name) {
            if (!name) return '';

            const nameParts = name.trim().split(' ');

            if (nameParts.length === 1) {
                return nameParts[0].charAt(0).toUpperCase();
            }

            const firstInitial = nameParts[0].charAt(0).toUpperCase();
            const secondInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();

            return `${firstInitial}${secondInitial}`;
        },
        getColorByInitial(name) {
            if (!name) return 'grey'; // default color if no name is provided

            const initial = name.trim().charAt(0).toUpperCase();
            return this.colorMap[initial] || 'grey'; // default to grey if the initial is not found
        }
    },
    setup() {
        const theme = useTheme();
        return {
            theme,
            setTheme: (darkmode) => {
                theme.global.name.value = darkmode ? "darkTheme" : "lightTheme";
            },
        };
    },
};
</script>

<style></style>
